import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Grid,
  Typography,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomLink from '../custom-link';

const SectionContainerLayoutWithFilter = ({
  title,
  children,
  baseLink,
  callbackFn,
  filters,
  isfiltersBiggerFont,
  viewAllText,
  isViewAll,
  isDivider,
  isDotButton,
  isArrowButton,
  actions,
}) => {
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(undefined);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    //  TODO: data-sal='zoom-in' data-sal-duration='1000'
    <Box mt={5}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* TITLE */}
            <Box clone flexGrow={1}>
              <Grid item>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='h4' color='primary' gutterBottom>
                    <Box fontWeight='fontWeightBold' style={{ textTransform: 'uppercase' }}>
                      {title}
                    </Box>
                  </Typography>
                  <Box mb={1}>{actions}</Box>
                </Box>
              </Grid>
            </Box>

            {/* LARGE SCREEN FILTERS */}
            <Box display={{ xs: 'none', sm: 'block' }} clone>
              <Grid item>
                <Box display='flex' pt={1}>
                  {/* VIEW ALL */}
                  {isViewAll && (
                    <Box px={1}>
                      {baseLink ? (
                        <CustomLink linkUrl={baseLink} linkText={viewAllText} type={1} />
                      ) : (
                        <Box clone fontWeight='fontWeightBold' style={{ textTransform: 'uppercase' }}>
                          <Typography variant={isfiltersBiggerFont ? 'body1' : 'overline'} color='textSecondary'>
                            {viewAllText}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* DIVIDER */}
                  {isDivider && (
                    <Box px={1}>
                      <Divider orientation='vertical' />
                    </Box>
                  )}

                  {/* FILTERS */}
                  {filters &&
                    filters.map((_filter, index) => (
                      <Box px={1} key={index}>
                        {callbackFn ? (
                          <CustomLink
                            linkText={_filter.name ? _filter.name : _filter}
                            type={5}
                            isfiltersBiggerFont={isfiltersBiggerFont}
                            isActive={isActive}
                            callbackFn={() => {
                              callbackFn(_filter);
                              setIsActive(_filter.name ? _filter.name : _filter);
                            }}
                          />
                        ) : (
                          <CustomLink
                            linkUrl={`${baseLink}/${_filter.code ? _filter.code : _filter}`}
                            linkText={_filter.name ? _filter.name : _filter}
                            type={1}
                          />
                        )}
                      </Box>
                    ))}
                </Box>
              </Grid>
            </Box>

            {/* MOBILE SCREEN FILTERS */}
            <Box display={{ xs: isDotButton || isArrowButton ? 'block' : 'none', sm: 'none' }} clone>
              <Grid item xs={12}>
                <IconButton
                  size='small'
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}>
                  {isDotButton && <MoreVertOutlined color='primary' />}
                  {isArrowButton && <KeyboardArrowDownIcon color='primary' />}
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement='bottom-end'
                  className='zIndex-1'
                  modifiers={{
                    flip: {
                      enabled: true,
                    },
                    arrow: { enabled: true },
                  }}>
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                            {/* FILTERS */}
                            {filters &&
                              filters.map((_filter, index) => (
                                <MenuItem key={index} onClick={handleClose}>
                                  {callbackFn ? (
                                    <Box
                                      px={1}
                                      key={index}
                                      width='100%'
                                      onClick={() => {
                                        callbackFn(_filter);
                                        setIsActive(_filter.name ? _filter.name : _filter);
                                      }}>
                                      <CustomLink
                                        linkText={_filter.name ? _filter.name : _filter}
                                        type={5}
                                        isActive={isActive}
                                        callbackFn={() => {
                                          callbackFn(_filter);
                                          setIsActive(_filter.name ? _filter.name : _filter);
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <Box px={1} key={index}>
                                      <CustomLink
                                        linkUrl={`${baseLink}/${_filter.code ? _filter.code : _filter}`}
                                        linkText={_filter.name ? _filter.name : _filter}
                                        type={1}
                                      />
                                    </Box>
                                  )}
                                </MenuItem>
                              ))}

                            {/* DIVIDER */}
                            {isDivider && <Divider light />}

                            {/* VIEW ALL */}
                            {isViewAll && (
                              <MenuItem>
                                {baseLink ? (
                                  <CustomLink linkUrl={baseLink} linkText={viewAllText} type={1} />
                                ) : (
                                  <Box clone fontWeight='fontWeightBold' style={{ textTransform: 'uppercase' }}>
                                    <Typography variant='overline' color='textSecondary'>
                                      {viewAllText}
                                    </Typography>
                                  </Box>
                                )}
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box width={1} mt={3}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

SectionContainerLayoutWithFilter.defaultProps = {
  title: '',
  viewAllText: 'View All',
  isViewAll: false,
  isDotButton: false,
  isDivider: false,
};

SectionContainerLayoutWithFilter.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  baseLink: PropTypes.string,
  callbackFn: PropTypes.func,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  ]),
  viewAllText: PropTypes.string.isRequired,
  isViewAll: PropTypes.bool.isRequired,
  isDotButton: PropTypes.bool.isRequired,
  isDivider: PropTypes.bool.isRequired,
};

export default SectionContainerLayoutWithFilter;
