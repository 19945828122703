import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, Typography, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';

const DigitalSeriesIndexPage = ({ pageContext: { _digitalSeriesYears, _digitalSeriesArray } }) => {
  const [years, setYears] = useState(_digitalSeriesYears.sort((a, b) => b - a));
  const [filteredDigitalSeries, setfilteredDigitalSeries] = useState(_digitalSeriesArray);
  const [isDataLoaded, setIsDataLoaded] = useState(true);

  return (
    <Layout>
      {
        <Box mt={10}>
          <SEO lang='en' title=' Digital Series | Global Manufacturing & Industrialisation Summit' />
          <SectionContainerLayoutWithFilter
            title='DIGITAL SERIES'
            baseLink='/digital-series'
            filters={years}
            isViewAll
            isDivider>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  The Digital Series is a collection of industry episodes tackling the biggest challenges facing the
                  industry during Covid-19 and beyond.
                </Typography>
              </Grid>
              {isDataLoaded ? (
                years.map((_year, index) => (
                  <Grid key={index} item xs={12}>
                    <Typography gutterBottom variant='h6' className='text-uppercase'>
                      <Box my={3}>{_year}</Box>
                    </Typography>
                    <Grid container spacing={3}>
                      {filteredDigitalSeries.map((_digitalSeries, dsIndex) => (
                        <Grid key={dsIndex} item xs={12} md={3}>
                          <CardImageTitleDescription
                            isImgTag
                            image={
                              _digitalSeries.file[0].type !== 'video/mp4'
                                ? _digitalSeries.file[0].code
                                : _digitalSeries.file[1].code
                            }
                            title={_digitalSeries.title}
                            body={_digitalSeries.description}
                            readMore={`/digital-series/${_digitalSeries.year}/${_digitalSeries.urlSlug}`}
                            lines={3}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                    <Typography color='textSecondary' variant='button'>
                      No Digital Series Found
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Box>
      }
    </Layout>
  );
};

export default DigitalSeriesIndexPage;
